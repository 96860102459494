import './App.css';
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import {HomeScreen} from "./screens/HomeScreen";
import {ServiceDetail} from "./components/ServiceDetail";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<HomeScreen />} exact/>
              <Route path="/service/:serviceId" element={<ServiceDetail />}/>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
