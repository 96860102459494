import React from "react";
import {Navbar, Nav, Container} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import logo from '../logo.svg';
import {HashLink} from "react-router-hash-link";

function NavigationBar(){

    return(
        <Navbar  collapseOnSelect sticky={"top"} expand="md" className="silver-gradient w-100 py-3">
            <Container fluid className="px-0 mx-3">
                <LinkContainer to={"/"}>
                    <Navbar.Brand>
                        <img src={logo} alt="Logo" width="80" height="80"/>
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        <LinkContainer to="/">
                            <Nav.Link className="mx-3 fs-4 text-dark nav-link-animate">
                                <HashLink className={"text-dark nav-link"} to={"#top"}>
                                    Home
                                </HashLink>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/">
                            <Nav.Link className="mx-3 fs-4 text-dark nav-link-animate">
                                <HashLink className={"nav-link"} to={"#services"}>
                                    Services
                                </HashLink>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/">
                            <Nav.Link className="mx-3 fs-4 text-dark nav-link-animate">
                                <HashLink className={"nav-link"} to={"#contact"}>
                                    Contact
                                </HashLink>
                            </Nav.Link>
                        </LinkContainer>
                        {/*<LinkContainer to="/">*/}
                        {/*    <Nav.Link className="mx-3 fs-4 text-dark nav-link-animate">About Us</Nav.Link>*/}
                        {/*</LinkContainer>*/}
                        {/*<LinkContainer to="/">*/}
                        {/*    <Nav.Link className="mx-3 fs-4 text-dark nav-link-animate">Partners</Nav.Link>*/}
                        {/*</LinkContainer>*/}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;