import React from 'react';
import {Container, Row, Col, Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {HashLink} from "react-router-hash-link";

export default function Footer() {
    return (
        <Container className="silver-gradient lead" fluid>
            <Row className="py-3 d-flex justify-content-center align-content-center my-4">
                <Col sm={12} md={12} lg={6}>
                    <h3 className="text-center text-secondary my-3 lead fs-3 py-3" id={"contact"}>Contact Information</h3>
                    <div className={"d-flex justify-content-around text-secondary"}>
                        <div>
                            <p>Telephone:</p>
                            <p>Email:</p>
                        </div>
                        <div>
                            <p><a href="tel:+971585854828" className="link-secondary">+971-58-585-4828</a></p>
                            <p><a href="mailto:info@ivimm.com" className="link-secondary">info@ivimm.com</a></p>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6} >
                    <h3 className="text-center text-secondary my-3 lead fs-3 py-3">Featuring</h3>
                    <div className={"px-3"}>
                        <div className={"py-2"}>
                            <LinkContainer to="/">
                                <Nav.Link className="mx-3 fs-5 text-secondary nav-link-animate">
                                    <HashLink className={"nav-link"} to={"#top"}>
                                        Home
                                    </HashLink>
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="">
                                <Nav.Link className="mx-3 fs-5 text-secondary nav-link-animate">
                                    <HashLink className={"nav-link"} to={"#services"}>
                                        Services
                                    </HashLink>
                                </Nav.Link>
                            </LinkContainer>
                        </div>
                        <div className={"py-2"}>
                        {/*<LinkContainer to="/partners">*/}
                        {/*    <Nav.Link className="mx-3 fs-5 text-secondary nav-link-animate">Partners</Nav.Link>*/}
                        {/*</LinkContainer>*/}
                        {/*<LinkContainer to="/about">*/}
                        {/*    <Nav.Link className="mx-3 fs-5 text-secondary nav-link-animate">About Us</Nav.Link>*/}
                        {/*</LinkContainer>*/}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={"bg-primary"}>
                    <FooterLine/>
                </Col>
            </Row>
        </Container>
    )
}



function FooterLine() {
    return (
        <Container  fluid>
            <p className="text-center text-secondary align-middle my-1 py-2">Copyright &copy; IVI Media Mosaic {new Date().getFullYear()}</p>
        </Container>
    )
}

