import React, {useEffect} from "react";
import NavigationBar from "../components/NavigationBar";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {Container, Row, Col} from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "../components/Footer";
import heroImage from "../assets/images/heroImage.webp";
import production from "../assets/images/blue-prod.svg";
import postProduction from "../assets/images/blue-pprod.svg";
import distribution from "../assets/images/blue-distribution.svg";

export function HomeScreen(){
    useEffect(() => {
        AOS.init({
            // You can set global configurations here
            duration: 1000, // Values from 0 to 3000, with step 50ms
            once: false, // Whether animation should happen only once - while scrolling down
            mirror: false, // Whether elements should animate out while scrolling past them
        });
    });
    useEffect(() => {
        // Get the blocks
        const topRightBlock = document.querySelector('.top-right-block');
        const bottomLeftBlock = document.querySelector('.bottom-left-block');

        // Add classes to start the animation
        topRightBlock.classList.add('slide-top-right');
        bottomLeftBlock.classList.add('slide-bottom-left');
    }, []);
    return (
        <div id={"top"}>
            <NavigationBar />
            <div className={'hero-background'} style={{ "backgroundImage": `url(${heroImage})` }}>
                <Container fluid className="h-100">
                    <Row className="h-50">
                        <Col className="col-12 d-flex align-items-center justify-content-center initial-position top-right-block">
                            <div className="bg-secondary text-center text-box bg-opacity-75">
                                <h1 className="text-primary lead fs-1">IVI Media Mosaic</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row className="h-50">
                        <Col className="col-12 d-flex align-items-center justify-content-center initial-position bottom-left-block">
                            <div className="bg-secondary text-center p-5 text-box bg-opacity-75">
                                <p className="text-primary fs-3">
                                    Mastering the Art of Visual Media.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={"text-justify"}>
                <Row>
                    <Col>
                        <Container data-aos="flip-right" className={"px-5 "}>
                            <h1 className={"text-secondary text-center py-4 lead fs-1"}>About Us</h1>
                            <p className={"fs-4 "}>
                                Our company is dedicated to a broad range of activities in the Motion Picture, Video, and Television Programme sectors. We engage comprehensively in the industry, covering production, post-production, and distribution. Within the dynamic field of distribution, we are committed to exploring innovative frontiers. We consistently broaden our network of partnerships and adopt novel models and strategies, adapting to the ever-changing digital marketplace.
                            </p>
                        </Container>
                    </Col>
                    <Col>
                        <Container data-aos="flip-left" className={"px-5 "}>
                            <h1 className={"text-secondary text-center py-4 lead fs-1"}>Partners</h1>
                            <p className={"fs-4 "}>
                                Through meaningful partnerships and collaborative endeavors, we not only provide consulting services for production and post-production activities but also facilitate connections between our clients and potential investors. Driven by progressive approaches, our mission is to deliver adaptable and innovative distribution strategies to our partners. With an unwavering commitment to excellence, we strive to be at the forefront of industry trends, ensuring a dynamic and impactful presence in the world of media and entertainment.
                            </p>
                        </Container>
                    </Col>
                </Row>
            </div>
            <div id={"services"}>
                <h1 className={"text-secondary text-center py-4 lead fs-1"}>Our Services</h1>
                <Container className={"my-4 py-2 pb-3"} >
                    <Row className={'d-flex justify-content-around'}>
                        <Col lg={3} md={6} sm={12} className={""}>
                            <ServiceItem link="/service/production" src={production} title={"Production"}/>
                        </Col>
                        <Col lg={3} md={6} sm={12} className={""}>
                            <ServiceItem link="/service/post-production" src={postProduction} title={"Post Production"}/>
                        </Col>
                        <Col lg={3} md={6} sm={12} className={""}>
                            <ServiceItem link="/service/distribution" src={distribution} title={"Distribution"}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

const ServiceItem = ({ src, title, link }) => {
    let navigate = useNavigate();

    const transitionToService = () => {
        // Start a loading animation
        // Pre-load the detailed content if necessary

        // Use the history API to navigate after the animation
        navigate(link);
    }

    return (
        <div className={"service-item my-5"} data-aos="zoom-in-up" onClick={transitionToService}>
            <motion.img
                src={src}
                className={"service-img"}
                style={{"cursor": "pointer"}}
                alt={title}
                layoutId={`service-${title}`} // Make sure this matches the detail layoutId
            />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className={"d-flex justify-content-center"}>
                    <p className={"text-dark text-center lead"}>{title}</p>
                </div>
            </motion.div>
        </div>
    );
};

