import React from "react";
import { motion } from 'framer-motion';
import {useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";


export function ServiceDetail() {
    const { serviceId } = useParams();
    const services = [
        {
            "key": "production",
            "title": "Production Services",
            "subTitle1": "Budgeting and Financial Planning",
            "par1": "IVI Media Mosaic specializes in providing financial consulting to ensure cost-effective production. Our services include aiding in the development of budgets and meticulous tracking of expenses.",
            "subTitle2": "Project Management",
            "par2": "At IVI Media Mosaic, we formulate comprehensive project plans, ensuring seamless coordination between internal and external stakeholders and managing client interactions effectively."
        },
        {
            "key": "post-production",
            "title": "Post-Production Services",
            "subTitle1": "Market Research and Trend Analysis",
            "par1": "We conduct in-depth research into the latest trends and techniques within the industry, coupled with targeted audience research to inform strategic marketing.",
            "subTitle2": "Strategic Investor Engagement",
            "par2": "Understanding our clients' unique needs allows us to create enduring connections with investors. We leverage our vast network of partnerships to facilitate tailored, successful engagements that cultivate long-term, mutually beneficial relationships."
        },
        {
            "key": "distribution",
            "title": "Distribution Services",
            "subTitle1": "Marketing and Distribution Strategy",
            "par1": "Our team is adept at developing tailored marketing and promotional plans. We provide expert consulting in distribution strategies, manage festival submissions, and coordinate premiere planning.",
            "subTitle2": "Legal and Contractual Guidance",
            "par2": "IVI Media Mosaic offers comprehensive consulting on contracts and agreements, advice on rights acquisition and licensing, and ensures compliance with industry regulations."
        }

    ];
    const service_details = services.find(service => service.key === serviceId);
    return(
        <motion.div
            layoutId={`service-${serviceId}`}
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, scale: 1, transition: { duration: 0.3 } }}
        >
            <ProductionDetails details={service_details} />
        </motion.div>
    )
}

function ProductionDetails({ details }){
    // You can define dynamic styles here
    const titleStyle = {
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out'
    };

    const subTitleStyle = {
        transition: 'transform 0.2s ease-in-out'
    };

    return (
        <div className={"m-4 p-4 shadow-lg silver-gradient text-justify"} style={{ minHeight: '95vh' }}>
            <h1
                className={'text-secondary text-center text interactive-title'}
                style={titleStyle}
                onMouseOver={({target}) => target.style.transform = 'scale(1.05)'}
                onMouseOut={({target}) => target.style.transform = 'scale(1)'}
            >
                {details.title}
            </h1>
            <Container >
                <Row className={'py-4'}>
                    <Col className={'col-12 p-3'}>
                        <div
                            className="interactive-subtitle"
                            style={subTitleStyle}
                            onMouseOver={({target}) => target.style.transform = 'translateY(-5px)'}
                            onMouseOut={({target}) => target.style.transform = 'translateY(0)'}
                        >
                            <p className={"lead fs-2 text-secondary"}>{details.subTitle1}</p>
                            <p className={'fs-4'}>{details.par1}</p>
                        </div>
                    </Col>
                </Row>
                <Row className={"py-4"}>
                    <Col className={'col-12 p-3 m-3'}>
                        <div
                            className="interactive-subtitle"
                            style={subTitleStyle}
                            onMouseOver={({target}) => target.style.transform = 'translateY(-5px)'}
                            onMouseOut={({target}) => target.style.transform = 'translateY(0)'}
                        >
                            <p className={"lead fs-2 text-secondary "}>{details.subTitle2}</p>
                            <p className={'fs-4 '}>{details.par2}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

